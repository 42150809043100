








































import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import { AgencyModel, UserModel } from "@/api/generated";
import Api from "@/api";
import User from "@/models/User";

const snackbarModule = getModule(SnackbarModule);

@Component({name: "user-autocomplete"})
export default class UserAutocomplete extends Vue {
  @Prop({ type: Boolean, default: false }) public noTitle!: boolean;
  public loading = false;
  public users: Array<UserModel> = [];

  public async created() {
    try {
      this.loading = true;
      const response = await Api.UserService.apiUserGet();
      this.users = response.data;
      this.users = this.users.sort((a, b) => a.firstname.localeCompare(b.firstname));
    } catch {
      snackbarModule.setSnackbarMessage("Failed to load users");
    } finally {
      this.loading = false;
    }
  }
}
