var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"container-xl"},[_c('base-data-table',{attrs:{"title":"Messages","headers":_vm.headers,"items":_vm.messages,"loading":_vm.loading,"server-items-length":_vm.numberOfItems,"noCreate":true,"noSearch":true,"options":_vm.options},on:{"update:options":[_vm.getData,function($event){_vm.options=$event}]},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('user-autocomplete',{attrs:{"item-text":"fullname","item-value":"userId","closable-chips":true,"no-title":true},model:{value:(_vm.usersFilter),callback:function ($$v) {_vm.usersFilter=$$v},expression:"usersFilter"}}),_c('base-btn',{staticStyle:{"height":"48px"},on:{"click":_vm.togglePriorityFilter}},[_vm._v(" "+_vm._s(_vm.isImportantMessagesOnly ? "Show all messages" : "Show only priority messages")+" ")])]},proxy:true},{key:"item.importanceLevel",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggleMessageImportance(item)}}},[(item.importanceLevel === 1)?_c('v-icon',{staticStyle:{"color":"red"}},[_vm._v("mdi-exclamation-thick")]):_c('v-icon',{staticStyle:{"color":"gray"}},[_vm._v("mdi-exclamation-thick")])],1)]}},{key:"item.chatRoom.name",fn:function(ref){
var item = ref.item;
return [(item.chatRoom.chatRoomType === 1)?_c('span',[_vm._v(" Emegency Chat ("+_vm._s(item.chatRoom.name)+") ")]):(item.chatRoom.chatRoomType === 2)?_c('span',[_vm._v(" General Chat ("+_vm._s(item.chatRoom.name)+") ")]):_c('span',[_vm._v(" "+_vm._s(item.chatRoom.name)+" ")])]}},{key:"item.author.firstname",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.author.firstname)+" "+_vm._s(item.author.lastname)+" ")]}},{key:"item.content",fn:function(ref){
var item = ref.item;
return [(item.content)?_c('span',[_vm._v(_vm._s(item.content))]):(item.attachedFileKey)?_c('span',[_vm._v("Message has a file attached.")]):_c('span',[_vm._v("N/A")])]}},{key:"item.attachedFileKey",fn:function(ref){
var item = ref.item;
return [(item.attachedFileKey)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.downloadFile(item)}}},[_c('v-icon',[_vm._v("mdi-download-circle-outline")])],1):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" "+_vm._s(_vm.formatTime(item.createdAt))+" ")]}},{key:"item.chatRoomId",fn:function(ref){
var item = ref.item;
return [(item.importanceLevel === 1)?_c('base-btn',{attrs:{"disabled":!item.author.linkedCrewMemberId},on:{"click":function($event){return _vm.saveToNotes(item)}}},[_vm._v("Save")]):_vm._e()]}},{key:"item.fileUrl",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteMessage(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }