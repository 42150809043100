





















































































import { appApiClient } from '@/services/apiService';
import { DataTableHeader } from 'vuetify';
import Message from "../../models/Message";
import nameof from '@/utility/nameof';
import Vue from 'vue';
import Moment from "../../utility/momentUtility";
import UserAutocomplete from "@/components/common/user/UserAutocomplete.vue";
import { get } from 'lodash';
import CrewMemberNoteCreateModel from '@/models/crewMemberNoteCreateModel';
import { getModule } from 'vuex-module-decorators';
import FormDataFactory from '../../utility/formDataFactory';
import { apiClient } from '../../services/apiService';
import SnackbarModule from '../../store/snackbarModule';
import { ContractType } from '@/api/generated';
const snackbarModule = getModule(SnackbarModule);

const headers: Array<DataTableHeader<Message>> = [
    { text: "", value: "actions", sortable: false },
    { text: "", value: "importanceLevel" },
    { text: "Group", value: "chatRoom.name" },
    { text: "Name", value: "author.firstname" },
    { text: "Content", value: nameof<Message>("content") },
    { text: "", value: nameof<Message>("attachedFileKey") },
    { text: "Created At", value: nameof<Message>("createdAt") },
    { text: "", value: nameof<Message>("chatRoomId")},
    { text: "", value: nameof<Message>("fileUrl")},
];

interface Data {
  isImportantMessagesOnly: boolean;
  numberOfItems: number;
  messages: Message[];
  headers: Array<DataTableHeader<Message>>;
  loading: boolean;
  options: Options;
  usersFilter: string[];
}

interface Options {
  groupBy: any[],
  groupDesc: any[],
  itemsPerPage: number,
  multiSort: boolean,
  page: number
}

export default Vue.extend({
  components: {UserAutocomplete},
  data(): Data {
    return {
      isImportantMessagesOnly: true,
      numberOfItems: 0,
      headers,
      messages: [],
      loading: true,
      options: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 10,
        multiSort: false,
        page: 1
      },
      usersFilter: []
    }
  },
  watch: {
    usersFilter: {
      handler() {
        this.getData(this.options);
      },
      deep: true
    },
  },
  computed: {
    priorityFilterValue() {
      if(this.isImportantMessagesOnly) {
        return 1;
      } else {
        return 0;
      }
    }
  },
  methods: {
    async togglePriorityFilter() {
      this.isImportantMessagesOnly = !this.isImportantMessagesOnly;
      this.options.page = 1;
      await this.getData(this.options);
    },

    async getData(options?: any) {
      try {
        const page = options.page;
        const itemsPerPage = options.itemsPerPage;

        const sortBy = options?.sortBy[0] ?? "createdAt";
        const isSortedDescending = options?.sortDesc[0] ?? true;

        this.loading = true;
        const response = await appApiClient.post(`/api/Admin/GetMessages`, {
          page,
          itemsPerPage,
          sortBy,
          isSortedDescending,
          importanceLevelFilter: this.priorityFilterValue,
          usersFilter: this.usersFilter
        });

        console.log(response.data.messages);
        
        this.numberOfItems = response.data.datasetTotalMessages;
        this.messages = response.data.messages;
      } finally {
        this.loading = false;
      }
    },

    async deleteMessage(message: Message) {
      try {
        const res = await this.$confirm('You are deleting this message, are you sure?', { title: "Warning" });

        if (!res) return;
        
        this.loading = true;
        await appApiClient.delete(`/api/Admin/DeleteMessage/${message.id}`);

        this.messages = this.messages.filter(x => x.id !== message.id);
      } finally {
        this.loading = false;
      }
    },

    async toggleMessageImportance(message: Message) {
      try {
        this.loading = true;
        const response = await appApiClient.post(`/api/Admin/SetMessageImportance`, {
          messageId: message.id,
          importanceLevel: message.importanceLevel === 1 ? 0 : 1
        });

        message.importanceLevel = response.data.importanceLevel;

        if(this.priorityFilterValue === 1 && message.importanceLevel !== 1) {
          this.messages = this.messages.filter((msg: Message) => message.id !== msg.id);
        }
      } finally {
        this.loading = false;
      }
    },

    async saveToNotes(message: Message) {
      if(!message.author?.linkedCrewMemberId) return snackbarModule.setSnackbarMessage("User is not a crew member.");

      const files : File[] = [];

      if(message.attachedFileKey) {
        const file = await this.getFile(message.attachedFileKey);

        if(file) files.push(file);
      }

      const model: CrewMemberNoteCreateModel = {
        crewMemberId: message.author?.linkedCrewMemberId,
        title: "Saved Chat Message",
        note: message.content ? message.content : "Sent a file.",
        evidence: files,
        fileName: "",
        discriminator: ContractType.NUMBER_2
      };

      try {
        this.loading = true;
        const formData = FormDataFactory.Create(model);
        await apiClient.post("api/crewMemberNote", formData);
        snackbarModule.setSnackbarMessage("Note created");
        this.$emit("note-created");

        await this.toggleMessageImportance(message);
      } catch {
        snackbarModule.setSnackbarMessage("Failed to create note");
      } finally {
        this.loading = false;
      }
    },

    async getFile(attachedFileKey: string) : Promise<File | undefined> {
      try {
        this.loading = true;

        const response = await appApiClient({
          method: 'GET',
          url: `/api/Media/Get/${attachedFileKey}`,
          responseType: 'blob'
        });

        if (response.status === 200) {
          const file = new File([response.data], attachedFileKey);
          return file;
        }
      } catch {
        return undefined;
      } finally {
        this.loading = false;
      }
    },

    async downloadFile(item: Message) {
      try {
        this.loading = true;

        const attachedFileKey: string = item.attachedFileKey!;
        
        const file = await this.getFile(attachedFileKey);
        if(!file) return;

        const url = window.URL.createObjectURL(file);
        const anchor = document.createElement('a');
        anchor.style.display = 'none';
        anchor.href = url;
        anchor.download = attachedFileKey;
        document.body.appendChild(anchor);
        anchor.click();
        window.URL.revokeObjectURL(url);
      } finally {
        this.loading = false;
      }
    },

    formatDate: Moment.formatDate,
    formatTime: Moment.formatTime
  }
});
